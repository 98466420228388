<template>
  <div class="d-flex justify-space-between" style="width: 100%">
    <div style="width: 100%">
      <v-row no-gutters>
        <v-col cols="8" lg="6">
          <div class="cart-item-details">
            <v-list-item two-line class="pl-0">
              <v-list-item-content class="pb-0">
                <v-list-item-title
                  class="font-weight-medium text-no-wrap"
                  style="
                    max-width: auto;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  "
                  >{{ item.product.name }}
                  <v-tooltip
                    content-class="ssfix_tooltip"
                    :open-on-hover="false"
                    v-if="item.note"
                    color="primary"
                    bottom
                    max-width="320"
                  >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:activator="{ on }">
                      <v-btn
                        small
                        dark
                        icon
                        @click="on.click"
                        @blur="on.blur"
                        retain-focus-on-click
                        :title="$t('click_for_view_note')"
                      >
                        <v-icon color="primary">mdi-comment-text</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ item.note }}</span>
                  </v-tooltip>

                  <template
                    v-if="
                      $store.getters.isDrugStore && item.product.info_medication
                    "
                  >
                    <v-tooltip
                      content-class="ssfix_tooltip"
                      :open-on-hover="false"
                      color="warning"
                      bottom
                      max-width="280"
                    >
                      <!-- eslint-disable-next-line -->
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          dark
                          icon
                          @click="on.click"
                          @blur="on.blur"
                          v-if="item.product.info_medication && item.product.info_medication.requires_formula"
                          retain-focus-on-click
                          :title="$t('click_prescription')"
                        >
                          <v-icon color="orange">mdi-prescription</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("description_prescription") }}</span>
                    </v-tooltip>
                    <v-tooltip
                      content-class="ssfix_tooltip"
                      :open-on-hover="false"
                      max-width="280"
                      color="red"
                      bottom
                    >
                      <!-- eslint-disable-next-line -->
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          dark
                          icon
                          @click="on.click"
                          @blur="on.blur"
                          v-if="item.product.info_medication && item.product.info_medication.controlled_product"
                          retain-focus-on-click
                          :title="$t('click_mce')"
                        >
                          <v-icon color="red" :class="{ blinking: isBlinking }"
                            >mdi-account-lock</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>{{ $t("description_mce") }}</span>
                    </v-tooltip>
                  </template>
                </v-list-item-title>
                <v-list-item-subtitle v-if="item.product_variant">{{
                  item.product_variant.name
                }}</v-list-item-subtitle>
                <v-list-item-subtitle
                  class="cart_item_quantity font-weight-regular"
                >
                  {{ item.quantity }} {{ item.product.unity.name }} X
                  {{ $filters.currency(getPriceUnitaryItem(item), 0) }}
                  <v-chip
                    color="red"
                    dark
                    small
                    v-if="item.percentage_discount > 0"
                  >
                    - {{ item.percentage_discount }}
                    <v-icon small right>mdi-percent</v-icon>
                  </v-chip>
                </v-list-item-subtitle>

                <template v-if="item.options">
                  <v-list-item-subtitle>
                    <div class="pl-1 mt-1">
                      <template v-if="item.sub_items">
                        <div class="d-block">
                          <template v-for="sub in item.sub_items">
                            <div :key="sub.id">
                              <h4
                                class="cart-item-title font-weight-medium grey--text text--darken-3"
                              >
                                <v-icon color="primary"
                                  >mdi-chevron-right</v-icon
                                >{{ sub.product.name }}
                              </h4>
                              <span class="cart_item_quantity subtitle-2 pl-5">
                                {{ sub.quantity }}
                                {{ sub.product.unity.name }} X
                                {{
                                  $filters.currency(getPriceUnitaryItem(sub), 0)
                                }}
                              </span>
                              <span class="cart-item-total subtitle-2">
                                + {{ $filters.currency(sub.total_amount, 0) }}
                              </span>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </v-list-item-subtitle>
                </template>
              </v-list-item-content>
            </v-list-item>

            <template v-if="item.options">
              <template v-for="(opt, index) in item.options">
                <template v-if="opt.option_type == 'custom'">
                  <!-- products, custom, category-->
                  <div class="cart-item-details" :key="index">
                    <v-icon color="primary">mdi-chevron-right</v-icon>

                    {{ opt.name }}:
                    <template v-if="opt.value.length > 0">
                      <div class="pl-6">
                        <v-chip
                          small
                          label
                          outlined
                          color="black"
                          class="mr-1"
                          v-for="o in opt.value"
                          :key="o.id"
                          >{{ o }}</v-chip
                        >
                      </div>
                    </template>
                    <template v-else>
                      <span>{{ opt.value }}</span>
                    </template>
                  </div>
                </template>
              </template>
            </template>
          </div>
        </v-col>
        <v-col cols="4" lg="6" class="d-flex justify-end align-center">
          <div
            class="cart-item-right-details text-center d-flex flex-lg-row flex-column"
            style="gap: 6px"
          >
            <div
              class="cart-item-total text-end pr-4 font-weight-regular grey--text text--darken-1"
            >
              {{ $filters.currency(item.total_amount, 0) }}
            </div>

            <div class="cart-item-actions my-auto">
              <v-btn-toggle borderless dense>
                <v-btn
                  small
                  color="orange lighten-5"
                  :title="$t('Agregar información de fórmula médica')"
                  @click="$emit('prescription:item', item)"
                  v-if="$store.getters.isDrugStore && item.product.info_medication && item.product.info_medication.requires_formula"
                >
                  <v-icon color="orange darken-2">mdi-prescription</v-icon>
                </v-btn>

                <v-btn
                  small
                  color="blue lighten-5"
                  :title="$t('edit')"
                  @click="$emit('edit:item', item)"
                >
                  <v-icon color="blue darken-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  small
                  color="red lighten-5"
                  :title="$t('delete')"
                  @click="$emit('delete:item', item)"
                >
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
        </v-col>
        <!---- price_amount {{ item.price_amount }},
        line_extension_amount {{ item.line_extension_amount }},
        tax_amount {{ item.tax_amount }},
        subtotal_amount {{ item.subtotal_amount }},
        item_amount {{ item.item_amount }},
        total_amount {{ item.total_amount }},
        unit_price {{ item.unit_price }},
        real_discount {{ item.real_discount }},
        discount_amount {{ item.discount_amount }},
        allowance_charges {{ item.allowance_charges }},
        taxes {{ item.taxes }},-->
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartSectionItem",
  data() {
    return {
      showTooltip: false,
    };
  },
  props: ["item"],
  mounted() {},
  computed: {
    isBlinking() {
      if (this.item.data_doctor && this.item.data_doctor.professional_card) {
        return false;
      }
      return true;
    },
  },
  methods: {
    getPriceUnitaryItem(item) {
      return item.unit_price;
    },
  },
};
</script>

<style>
.ssfix_tooltip {
  opacity: 1 !important;
}
.blinking {
  animation: blink 0.5s infinite alternate;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
</style>