<template>
  <v-card>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card outlined>
            <v-card-title class="justify-center"
              ><v-icon color="primary" class="pr-2">mdi-account</v-icon
              >{{ getCustomer().first_name + " " + getCustomer().last_name }}
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <v-alert color="info" text>
                    Puntos del cliente: {{ getCustomer().current_points }}
                    <br />
                    Valor equivalente de descuento:
                    {{ convertAmmountDiscount() }}
                    <br />
                    <span v-if="usedPoints"
                      >Puntos utilizados: {{ points }}</span
                    >
                  </v-alert>
                </v-col>
                <!-- <v-col cols="12">
                  <v-switch
                    label="Utilizar Todos los Puntos"
                    hide-details
                    v-model="consumePoints"
                    @change="useTotalPoints()"
                  ></v-switch>
                </v-col> -->
                <template v-if="!usedPoints">
                  <v-col cols="9">
                    <ng-number
                      label="Puntos"
                      @input="handleChangePoints()"
                      :rules="{ min_value: getMinPoints() }"
                      v-model="points"
                    ></ng-number>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      small
                      color="red"
                      fab
                      @click="handleChangePoints('decrease')"
                      class="mx-2"
                      :title="$t('decrease')"
                    >
                      <v-icon color="white">mdi-minus</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      color="success"
                      fab
                      :title="$t('increase')"
                      @click="handleChangePoints('increase')"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </template>
                <v-col cols="12" v-if="!usedPoints" class="d-flex justify-end" >
                  <v-btn
                    @click="applyPoints()"
                    color="primary"
                    :disabled="points < getMinPoints()"
                  >
                    <v-icon>mdi-check-outline</v-icon> Aplicar Puntos</v-btn
                  >
                </v-col>
                <v-col cols="12" v-else class="d-flex justify-end" >
                  <v-btn @click="cancelOrderPoints()" :disabled="!usedPoints" color="error" outlined>
                    Remover Puntos</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { useDate } from "vuetify";

export default {
  props: ["order"],
  data() {
    return {
      // consumePoints: false,
      points: 0,
      usedPoints: false,
    };
  },
  mounted() {
    this.points = this.order.points_used;
  },
  methods: {
    getCustomer() {
      return this.order.data_customer ?? {};
    },
    handleChangePoints(type = null) {
      let customer = this.getCustomer();
      if (type) {
        if (type == "increase") {
          this.points++;
        } else {
          this.points--;
        }
      }
      let points = 0;

      this.$nextTick(() => {
        if (this.points > customer.current_points) {
          this.points = customer.current_points;
        } else if (this.points != "" && this.points <= 0) {
          this.points = 0;
        }
        if (this.exceedBalance()) {
          this.points = this.getPointsBalance();
        }
      });
    },
    applyPoints() {
      this.updateOrderPoints();
      this.usedPoints = true;
    },
    exceedBalance() {
      let balance = this.getBalance();
      let amountByPoints = this.convertAmmountDiscount(false);
      if (balance <= 0 || balance - amountByPoints < 0) {
        return true;
      } else {
        return false;
      }
    },
    getPointsBalance() {
      let balance = this.getBalance();
      let amountByPoint = this.$store.getters.getGeneralValue(
        "gen_others_discount_value_per_point"
      );
      if (balance <= 0) {
        return 0;
      }
      let customer = this.getCustomer();
      let totalPoints = 0;

      if (customer.current_points > 0) {
        totalPoints = Math.floor(balance / amountByPoint);
      }
      if (totalPoints > customer.current_points) {
        totalPoints = customer.current_points;
      }
      return totalPoints;
    },
    getMinPoints() {
      let minValue = this.$store.getters.getGeneralValue(
        "gen_others_minimum_number_of_redeemable_points"
      );
      return minValue;
    },
    getBalance() {
      let order = this.$pos.getCurrentOrder();
      return order.getBalance() + order.getDiscountPoints();
    },

    // useTotalPoints() {
    //   if (this.consumePoints) {
    //     let customer = this.getCustomer();
    //     this.$nextTick(() => {
    //       this.points = customer.current_points;
    //       if (this.exceedBalance()) {
    //         this.points = this.getPointsBalance();
    //       }
    //     });
    //   }
    // },
    updateOrderPoints() {
      if (this.points >= this.getMinPoints()) {
        let amount = this.convertAmmountDiscount(false);
        this.$pos.getCurrentOrder().setPointsUsed(this.points);
        this.$pos.getCurrentOrder().setDiscountPoints(amount);
        this.$pos.getCurrentOrder().setTotalGlobalDiscounts(amount);
      } else {
        this.$pos.getCurrentOrder().setPointsUsed(0);
        this.$pos.getCurrentOrder().setDiscountPoints(0);
        this.$pos.getCurrentOrder().setTotalGlobalDiscounts(0);
      }
    },
    cancelOrderPoints() {
      this.points = 0;
      this.usedPoints = false;
      // this.consumePoints = false;
      this.$pos.getCurrentOrder().setPointsUsed(0);
      this.$pos.getCurrentOrder().setDiscountPoints(0);
      this.$pos.getCurrentOrder().setTotalGlobalDiscounts(0);
    },
    convertAmmountDiscount(currencyFormat = true) {
      let amountByPoint = this.$store.getters.getGeneralValue(
        "gen_others_discount_value_per_point"
      );
      if (this.points >= 0) {
        if (currencyFormat) {
          return this.$filters.currency(this.points * amountByPoint);
        } else {
          return this.points * amountByPoint;
        }
      } else {
        return 0;
      }
    },
  },
};
</script>